<!--
 * @Author: 月魂
 * @Date: 2021-12-21 13:40:02
 * @LastEditTime: 2022-11-14 17:52:45
 * @LastEditors: 月魂
 * @Description: 潜心身教
 * @FilePath: \food-show\src\views\Teaching.vue
-->
<template>
  <div class="box">
    <div class="cont">
      <Navigation :title="this.$t('teaching.title')" />
      <img src="../assets/temp/teaching.png" alt="" class="img top-bg">
      <img src="../assets/temp/teaching-pc.png" alt="" class="img top-bg pc">
      <div class="intro">
        <div class="des" v-for="(item, index) in this.$t('teaching.des')" :key="index">{{ item }}</div>
      </div>
      <div class="s-cont">
        <div class="item" v-for="item in $t('teaching.list')" :key="item.id" @click="showVideo(item)">
          <div class="poster">
            <img :src="domain + item.posterUrl" alt="" class="img">
            <van-icon name="play-circle-o" size="36" class="play" />
          </div>
          <div class="bottom">
            <div class="title">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频预览组件 -->
    <van-overlay :show="show" @click="handleClose">
      <div class="wrapper" v-if="video.posterUrl">
        <div class="wrapper-cont" @click.stop>
          <video controls class="video" :poster="domain + video.posterUrl">
            <source :src="domain + video.videoUrl">
          </video>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import Vue from 'vue'
import { Overlay } from 'vant'

Vue.use(Overlay)

export default {
  name: 'Teaching',
  components: {
    Navigation
  },
  data() {
    return {
      title: '',
      domain: '',
      show: false,
      video: {}
    }
  },
  mounted() {
    this.title = this.$t('teaching.title')
    this.domain = this.$t('domain')
    this.wxShare(this.$t('title'), this.$t('teaching.title'))
  },
  methods: {
    showVideo(param) {
      this.video = param
      this.show = true
    },
    handleClose() {
      this.video = {}
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #06062B;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  .cont {
    max-width: 1200PX;
    margin: auto;
    .top-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .pc {
      display: none;
    }
    @media (min-width: 1000PX) {
      .top-bg {
        display: none;
      }
      .pc {
        display: block;
      }
    }
    .intro {
      width: 100%;
      padding: 16px;
      max-width: 1200PX;
      margin: auto;
      position: relative;
      z-index: 1;
      .des {
        font-size: 12px;
        color: #999;
        @media (min-width: 1000PX) {
          & {
            color: #ccc;
          }
        }
      }
    }
    .s-cont {
      width: 100%;
      padding: 16px;
      font-size: 12px;
      .item {
        margin-bottom: 16px;
        border-radius: 15px;
        background: #111135;
        overflow: hidden;
        .img, .video {
          display: block;
          width: 100%;
          border-radius: 15px;
        }
        .poster {
          border-radius: 15px;
          overflow: hidden;
          position: relative;
          .play {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .bottom {
          padding: 16px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          line-height: 20px;
          .title {
            font-size: 14px;
            color: #ccc;
          }
          .des {
            color: #999;
            margin-top: 8px;
          }
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 16px;
    .wrapper-cont {
      width: 100%;
      max-width: 1200PX;
      background: #fff;
      border-radius: 15px;
      overflow: hidden;
      .video {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>